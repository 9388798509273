<script setup>
import Button from '@/Components/Button/Button.vue';
import { useForm } from '@inertiajs/vue3';
import { useDebounceFn } from '@vueuse/core';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

import Pagination from '@/Components/Pagination/PaginationFE.vue';
import Table from '@/Components/Table/Table.vue';
import StaffLayout from '@/Layouts/StaffLayout.vue';

import Filters from '@/Components/Table/Filters.vue';

const { t } = useI18n();
const route = inject('route');
const props = defineProps({
    query: Object,
    flexers: Object,
});

const form = useForm({
    search: props.query.search ?? '',
    orderBy: props.query.orderBy ?? '',
    orderDir: props.query.orderDir ?? 'desc',
    page: props.query.page ?? 1,
});

const getData = (resetPage = true) => {
    form.page = resetPage ? 1 : form.page;

    form.get(route('staff.flexers.index'), {
        preserveState: true,
        preserveScroll: true,
        only: ['flexers', 'query'],
    });
};

const debouncedGetData = useDebounceFn(() => getData(), 300);

const typeFilters = {};

const tableSettings = [
    { label: t('Name'), orderBy: 'name', width: '15%' },
    { label: t('E-mail address'), cell: 'Email', width: '10%' },
    { label: t('Phone number'), cell: 'Tel', width: '10%' },
    { label: t('Rating'), orderBy: 'rating', width: '15%', cell: 'Rating' },
    { label: t('Type'), width: '15%' },
    { label: t('Registration completed'), width: '10%', cell: 'Boolean' },
    { label: '', width: '2%', cell: 'Arrow' },
];

const tableData = computed(() =>
    props.flexers.data.map((flexer) => {
        return {
            id: flexer.id,
            rowData: [
                flexer.display_name,
                flexer.email,
                flexer.phone_number,
                [flexer.rating, flexer.received_reviews_count],
                flexer.type,
                flexer.registration_completed,
                route('staff.flexers.show', flexer.id),
            ],
        };
    })
);

const setPage = (e) => {
    form.page = e;
    getData(false);
};
</script>

<template>
    <StaffLayout :title="$t(`Fl@xr's`)">
        <Filters
            :form="form"
            @anyUpdate="getData()"
            @searchUpdate="debouncedGetData()"
            :h1="$t(`Fl@xr's`)"
            :typeFilters="typeFilters"
            :hasSearch="true"
        >
            <Button
                blue
                icon="/icon/download-excel.svg"
                type="button"
                asA
                class="ml-2"
                :href="route('staff.user-data.download')"
                :text="$t('Export')"
            />
        </Filters>

        <template v-if="typeof flexers !== 'undefined' && flexers.data.length > 0">
            <Table
                class="mb-8"
                :loading="form.processing"
                :tableSettings="tableSettings"
                :data="tableData"
                v-model:orderBy="form.orderBy"
                @update:orderBy="getData()"
                v-model:orderDir="form.orderDir"
                @update:orderDir="getData()"
            />
            <Pagination
                v-if="typeof flexers !== 'undefined'"
                :links="flexers.links"
                @setPage="setPage"
                :pageData="flexers"
                :hideTotal="query.search != '' && query.search != null"
            />
        </template>
        <template v-else>
            <div class="w-full px-4 py-3 overflow-hidden font-bold leading-tight bg-white rounded">
                {{ $t('No {model} found', { model: $t(`Fl@xr's`) }) }}
            </div>
        </template>
    </StaffLayout>
</template>
